import {
  REQUIRED_FIELD_ERROR,
  MUST_BE_NUMBER_ERROR,
  ONLY_CHARACHTERS_ALLOWED,
  EMAIL_BAD_FORMAT_ERROR,
  SELECT_PROJECT_TYPE_ERROR,
  DATE_CONSISTENCY_ERROR,
  MUST_BE_STRING_ERROR,
} from "../../assets/strings/errorMessages";

//COMPOSE VALIDATORS - apply multiple validators
export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

//CHECKS IF FIELD IS EMPTY, RETURNS ERROR IF IT IS
export const requiredFieldValidation = (value) =>
  value ? undefined : REQUIRED_FIELD_ERROR;

//ONLY NUMBERS ALLOWED CHECK, RETURN ERROR IF IT IS NOT
export const mustBeNumber = (value) =>
  isNaN(value) ? MUST_BE_NUMBER_ERROR : undefined;

//ONLY CHARACHTERS ALLOWED CHECK
export const mustBeCharachters = (value) =>
  /[^A-Za-z ]/.test(value) ? ONLY_CHARACHTERS_ALLOWED : undefined;

//check if there is  any of the following charachters ` ! @ # $ % ^ & ( ) _ + \ - = [ ] { } ; ' " \ | < > / ? ~
export const mustBeString = (value) =>
  /[`!@#$%^&()_+\-=\[\]{};':"\\|<>\/?~]/.test(value)
    ? MUST_BE_STRING_ERROR
    : undefined;

//CHECK EMAIL FORMAT
export const validateEmail = (value) =>
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
    ? undefined
    : EMAIL_BAD_FORMAT_ERROR;

//--------------------VALIDATION QUERIES FOR PASSWORD-------------------------

export const checkPasswordRequirements = (password) => {
  const requirements = {
    "jedno veliko slovo": /(?=.*[A-Z])/,
    "jedno malo slovo": /(?=.*[a-z])/,
    "jedan broj": /(?=.*\d)/,
    "jedan specijalan karakter": /[!@#$%^&*]/,
  };

  const errors = [];
  errors.push("Lozinka mora sadržati najmanje: ");
  Object.entries(requirements).forEach(([key, regex]) => {
    if (!regex.test(password)) {
      errors.push(`${key},`);
    }
  });

  if (password.length < 8) {
    errors.push("najmanje 8 karaktera.");
  }

  //Remove comma from the end and set dot instead
  if (errors.length > 0) {
    // Replace the last comma with a period
    errors[errors.length - 1] = errors[errors.length - 1].replace(/,$/, ".");
  }

  return errors.length === 1 ? null : errors;
};
// ----------------------------------------------------------------------------

//CHECK DATE FORMAT
export const isDateValid = (value) => {
  return !isNaN(new Date(value));
};

//COMPARE IF END DATE IS LOWER THAN START DATE
export const areDatesConsist = (value, allValues) => {
  const startDate = allValues.startDate;
  const check =
    startDate && new Date(startDate) > new Date(value)
      ? DATE_CONSISTENCY_ERROR
      : undefined;
  return startDate && new Date(startDate) > new Date(value)
    ? DATE_CONSISTENCY_ERROR
    : undefined;
};

export const areDatesConsist1 = (startDate, endDate) => {
  return new Date(startDate) > new Date(endDate)
    ? DATE_CONSISTENCY_ERROR
    : undefined;
};

//Check DropDown Selection
export const checkDropdownSelection = (value) => {
  return !value ? SELECT_PROJECT_TYPE_ERROR : undefined;
};

// if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
//   errors.endDate = 'End date must be after the start date';
// }
//Check multiple selection
export const validateMultipleSelection = (value) =>
  value && value.length > 0 ? undefined : "Selektujte najmanje jednu opciju";
