import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import ErrorCodePage from "../../../pages/ErrorCodePage";
import InfoCard from "../../common/InfoCard";
import ReadinessScore from "./ReadinessScore";
import Requirements from "./Requirements";
import { PageTitleContext } from "../../../context/PageTitleContext";
import { mapDataToDTO } from "../../../utils/helpers/helperFunctions";

const ComplianceElementDetails = () => {
  const { setPageTitle } = useContext(PageTitleContext);

  //This is going to be available from global state, once API is functioning
  let frameworks = [];

  const { type } = useParams();

  if (type === "framework") {
    frameworks = [
      {
        id: "1",
        title: "ISO27001",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        allowed: false,
      },
      {
        id: "2",
        title: "HIPAA",
        description: "Lorem and typesetting industry.",
        allowed: true,
      },
      {
        id: "3",
        title: "ISO9001",
        description: "Lorem Ipsum is simply dummy text of the printing ",
        allowed: true,
      },
      {
        id: "4",
        title: "ISO27002 ",
        description: "Lorem Ipsum is simply dummy ",
        allowed: false,
      },
      {
        id: "5",
        title: "NIST",
        description: "Lorem Ipsum  of the printing and typesetting industry.",
        allowed: true,
      },
    ];
  } else if (type === "law") {
    frameworks = [
      {
        id: "1",
        title: "GDPR",
        description:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        allowed: true,
        type: "law",
      },
      {
        id: "2",
        title: "Zakon o informacionoj bezbednosti RS",
        description: "Lorem and typesetting industry.",
        allowed: false,
        type: "law",
      },
      {
        id: "3",
        title: "Zakon o peri lazicu",
        description: "Lorem Ipsum is simply dummy text of the printing ",
        allowed: true,
        type: "law",
      },
    ];
  }

  const selectedFramework = {
    id: "1",
    title: "ISO27001",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    allowed: false,
  };

  const data = [
    {
      id: "1",
      name: "frameworkName",
      value: "ISO27001",
      label: "Naziv",
      type: "text",
    },
    {
      id: "2",
      name: "frameworkDescription",
      value:
        "Opis projekta Opis projekta Opis projekta Opis projekta Opis projekta Opis projekta Opis projekta Opis projekta Opis projekta Opis 20 Opis projektaOpis projekta",
      label: "Naziv",
      type: "text",
    },
    {
      id: "12",
      name: "frameworkDescription",
      value: "Opis projekta",
      label: "Naziv",
      type: "text",
    },
    {
      id: "13",
      name: "frameworkDescription",
      value:
        "Opis projekta Opis projekta Opis projekta Opis projekta Opis projekta Opis projekta Opis projekta Opis projekta Opis projekta Opis 20 Opis projektaOpis projekta",
      label: "Naziv",
      type: "text",
    },
    {
      id: "15",
      name: "frameworkDescription",
      value: "Opis projekta",
      label: "Naziv",
      type: "text",
    },
    {
      id: "16",
      name: "frameworkDescription",
      value: "Opis projekta",
      label: "Naziv",
      type: "text",
    },
    {
      id: "19",
      name: "frameworkDescription",
      value: "Opis projekta",
      label: "Naziv",
      type: "text",
    },
  ];

  const outputData = mapDataToDTO(data);
  const frameworksOutputData = mapDataToDTO(frameworks);

  //Get ID of item from URL and use it to filter the list in order to show appropriate data
  const { id } = useParams();
  // const selectedFramework = frameworksOutputData.find(
  //   (framework) => framework.id === id
  // );
  setPageTitle(selectedFramework.title);

  //Check if selected project exists
  if (!selectedFramework) {
    return <ErrorCodePage code="404" />;
  }

  return (
    <>
      <div className="w-full">
        <div className="flex w-full max-lg:flex-col">
          <div className="px-6 pb-6 lg:w-1/2">
            {/*Show data array */}
            <InfoCard data={outputData} type="project" />
            {/* <FileUpload /> */}
          </div>
          <div className="pb-6 pr-6 lg:w-1/2 max-lg:pl-6 ">
            {/* Za project readiness se mogu prosledjivati podaci, admimnistratori ce videti overall napredak, regulare korisnici samo napredak za zadatke na koje su dodeljeni? */}
            <ReadinessScore id={id} type={type} />
          </div>
        </div>
        <div>
          <Requirements />
        </div>
      </div>
    </>
  );
};

export default ComplianceElementDetails;
