import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { TextAreaComment } from "../../common/FormComponents";
import { requiredFieldValidation } from "../../../utils/helpers/validations";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  addTaskComment,
  deleteTaskComment,
  getTaskComments,
} from "../../../api/slices/taskSlice";
import { useModal } from "../../../context/ModalContext";

const CommentSideBar = ({ taskId, onClose, onAddComment }) => {
  const dispatch = useDispatch();
  const { taskComments } = useSelector((state) => state.task);

  useEffect(() => {
    dispatch(getTaskComments({ id: taskId }));
  }, []);

  const [visibleComments, setVisibleComments] = useState(2); // show 3 comments

  const handleLoadMore = () => {
    setVisibleComments((prevCount) => prevCount + 3);
  };

  //==================================== Add comment================================================
  const onSubmit = async (formValues) => {
    dispatch(addTaskComment({ id: taskId, content: formValues.comment }));
    dispatch(getTaskComments({ id: taskId }));
    onAddComment({ text: formValues.comment });
  };
  const deleteComment = (id) => {
    console.log("brisanje kom");
    dispatch(deleteTaskComment({ id }));
  };

  //==========================================================================================
  const handleDelete = (id) => {
    console.log("Comment deleted!", id);
  };

  const currentUser = useSelector((state) => state.auth.user);
  const currentUserName = `${currentUser?.firstName} ${currentUser?.lastName}`;

  const { openModal, closeModal } = useModal();
  return (
    <div className="p-3 rounded-md bg-primary-55 dark:bg-primary-800 ">
      <div className="flex justify-between">
        <h2 className="mb-4 text-xl font-semibold">Komentari</h2>
        <XMarkIcon className="w-6 h-6 cursor-pointer" onClick={onClose} />
      </div>
      {taskComments &&
        taskComments.slice(0, visibleComments).map((comment) => (
          <div key={comment.id} className="mb-4">
            <div className="flex justify-between mb-4">
              <div className="font-semibold">{comment.author}</div>
              <div className="text-sm text-gray-500">
                {new Date(comment.createdAt).toLocaleDateString()}
              </div>
            </div>

            <p>{comment.content}</p>
            {currentUserName === comment.author && (
              <div className="flex justify-end ">
                <TrashIcon
                  className="w-4 h-4 cursor-pointer"
                  onClick={() =>
                    openModal("Modal1", {
                      text: `Da li ste sigurni da želite da obrišete komentar?`,
                      close: closeModal,
                      confirm: () => deleteComment(comment.id),
                    })
                  }
                />
              </div>
            )}

            <hr className="mt-4 mb-2"></hr>
          </div>
        ))}

      {/*show LoadMore button if comment.length>3 */}
      {taskComments && taskComments.length > visibleComments && (
        <button
          className="text-blue-500 cursor-pointer"
          onClick={handleLoadMore}
        >
          Load More
        </button>
      )}

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="mb-6">
            <Field
              name="comment"
              component={TextAreaComment}
              type="text"
              required={true}
              validate={requiredFieldValidation}
              rows="1"
            />
            <button type="submit" className="mt-2 button-info">
              Dodaj komentar +
            </button>
          </form>
        )}
      />
    </div>
  );
};

export default CommentSideBar;
