import React from "react";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import {
  composeValidators,
  requiredFieldValidation,
  mustBeString,
} from "../../utils/helpers/validations";
import { InputField } from "../../components/common/FormComponents";
import logoImg from "../../assets/images/complit.png";
import TermsAndPrivacyPolicyFooter from "../../components/common/TermsAndPrivacyPolicyFooter";

const ResetPasswordEmail = () => {
  const onSubmit = (values) => {
    console.log("login call");
  };

  return (
    <>
      <section className="flex flex-col items-center justify-between min-h-screen bg-gray-50 dark:bg-primary-750">
        <div className="flex items-center justify-center flex-grow w-full">
          <div className="flex flex-col items-center w-full px-5">
            <Link
              to="#"
              className="flex items-center mb-2 text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <img className="w-24 mr-2 h-14" src={logoImg} alt="logo" />
            </Link>
            <div className="mb-2 text-xl dark:text-white">
              Compliance • Automated
            </div>
            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-primary-800 dark:border-gray-700">
              <div className="p-6 sm:p-8">
                <h1 className="mb-4 text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl dark:text-white">
                  Unesite email Vašeg naloga za koji želite resetovati lozinku
                </h1>
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className="relative flex flex-col">
                        {/* Username  input field */}
                        <Field
                          name="email"
                          component={InputField}
                          type="text"
                          required={true}
                          validate={composeValidators(
                            requiredFieldValidation,
                            mustBeString
                          )}
                          placeholder="Vaš email"
                        />
                      </div>
                      <button
                        type="submit"
                        className=" w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      >
                        Pošalji zahtev
                      </button>
                    </form>
                  )}
                />
                <div className="flex items-center justify-center mt-3">
                  <Link
                    to="/auth/login"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Imaš nalog? Uloguj se!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TermsAndPrivacyPolicyFooter />
      </section>
    </>
  );
};

export default ResetPasswordEmail;
