import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import ErrorCodePage from "../../../../pages/ErrorCodePage";
import InfoCard from "../../../common/InfoCard";
import ReadinessScore from "../ReadinessScore";
import Requirements from "../Requirements";
import { PageTitleContext } from "../../../../context/PageTitleContext";
import { getStandardById } from "../../../../api/slices/standardSlice";
import { useDispatch, useSelector } from "react-redux";

const StandardDetails = () => {
  const { setPageTitle } = useContext(PageTitleContext);

  const dispatch = useDispatch();

  const { standardDetails: standard } = useSelector((state) => state.standard);
  //Get ID of item from URL and use it to fetch details
  const { id } = useParams();

  useEffect(() => {
    if (!standard || standard.id !== id) {
      console.log("GET STANDAARD");
      dispatch(getStandardById({ id }));
    } else {
      setPageTitle(standard.title);
    }
  }, []);
  console.log("GET STANDAARD", standard);
  const { type } = useParams();

  return (
    <>
      <div className="w-full overflow-x-hidden ">
        <div className="flex w-full max-lg:flex-col">
          <div className="px-3 pb-6 lg:px-6 md:px-6 lg:w-1/2">
            {/*Show data array */}
            {standard && <InfoCard data={standard} type="project" />}
            {/* <FileUpload /> */}
          </div>
          <div className="px-3 pb-6 lg:pr-6 lg:w-1/2 lg:max-lg:pl-6 md:pr-6 md:max-lg:pl-6">
            {/* Za project readiness se mogu prosledjivati podaci, admimnistratori ce videti overall napredak, regulare korisnici samo napredak za zadatke na koje su dodeljeni? */}
            <ReadinessScore id={id} type={type} />
          </div>
        </div>
        <div>
          {standard && <Requirements requirements={standard.requirements} />}
        </div>
      </div>
    </>
  );
};

export default StandardDetails;
