import apiService from "./apiService";

//GET laws
const fetchLaws = async () => {
  const response = await apiService.getData(`laws`);
  return response.data?.laws;
};
//get law by id
const getLawById = async (id) => {
  const response = await apiService.getData(`laws/${id}`);
  return response.data?.law;
};

export const lawsService = {
  fetchLaws,
  getLawById,
};
