import apiService from "./apiService";
import { createAsyncThunk } from "@reduxjs/toolkit";

//GET
export const fetchProjects = createAsyncThunk(
  "project/fetchProjects",
  async () => {
    try {
      const response = await apiService.getData("/api/v1/projects");
      return response;
    } catch (error) {
      throw error;
    }
  }
);

//POST
export const postProjectDataAsync = createAsyncThunk(
  "project/projectDataAsync",
  async (data) => {
    try {
      const result = await apiService.postData("/api/v1/projects", data);
      return result;
    } catch (error) {
      throw new Error("Error during API request");
    }
  }
);

//DELETE
export const deleteProject = createAsyncThunk(
  "project/deleteProject",
  async (id) => {
    try {
      const response = await apiService.deleteData(
        `/api/v1/project?projectId=${id}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
