import React, { useContext, useEffect } from "react";
import { PageTitleContext } from "../../../context/PageTitleContext";
import TableRowSelection from "../../common/tables/TableRowSelection";
import { useModal } from "../../../context/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../../api/slices/documentSlice";

const Documents = () => {
  const { setPageTitle } = useContext(PageTitleContext);
  setPageTitle("Dokumenti");
  const { openModal } = useModal();

  const dispatch = useDispatch();
  const { documents } = useSelector((state) => state.documents);

  useEffect(() => {
    {
      dispatch(getDocuments());
    }
  }, []);

  const headerNamesMapper = {
    id: "ID",
    name: "Naziv",
    shortDescription: "Kratak opis",
    type: "Tip",
    author: "Autor",
    datePublished: "Datum objavljivanja",
  };
  // Display names for headers
  const headers = [
    "id",
    "documentName",
    "shortDescription",
    "type",
    "author",
    "datePublished",
  ];
  const data = [
    {
      id: "1",
      name: "TLorem Ipsum Colorita",
      shortDescription: "Kratak opis",
      type: "bbbbb",
      author: "Neko Neko",
      datePublished: "12.04.2024.",
    },
    {
      id: "2",
      name: "TLorem Ipsum Colorita",
      shortDescription: "Kratak opis",
      type: "bbbbb",
      author: "Neko Neko",
      datePublished: "12.04.2024.",
    },
    {
      id: "3",
      name: "TLorem Ipsum Colorit",
      shortDescription: "Kratak opis",
      type: "bbbbb",
      author: "Neko Neko",
      datePublished: "12.04.2024.",
    },
    {
      id: "4",
      name: "TLorem Ipsum Colorit",
      shortDescription: "Kratak opis",
      type: "bbbbb",
      author: "Neko Neko",
      datePublished: "12.04.2024.",
    },
    {
      id: "5",
      name: "TLorem Ipsum Colorit",
      shortDescription: "Kratak opis",
      type: "bbbbb",
      author: "Neko Neko",
      datePublished: "12.04.2024.",
    },
    {
      id: "6",
      name: "TLorem Ipsum Colorit",
      shortDescription: "Kratak opis",
      type: "bbbbb",
      author: "Neko Neko",
      datePublished: "12.04.2024.",
    },
    {
      id: "7",
      name: "TLorem Ipsum Colorit",
      shortDescription: "Kratak opis",
      type: "bbbbb",
      author: "Neko Neko",
      datePublished: "12.04.2024.",
    },
    {
      id: "8",
      name: "TLorem Ipsum Colorit",
      shortDescription: "CKratak opis",
      type: "bbbbb",
      author: "Neko Neko",
      datePublished: "12.04.2024.",
    },
    {
      id: "9",
      name: "ALorem Ipsum Colorit",
      shortDescription: "Kratak opis",
      type: "bbbbb",
      author: "Neko Neko",
      datePublished: "12.04.2024.",
    },
    {
      id: "10",
      name: "TLorem Ipsum Colorit",
      shortDescription: "Kratak opis",
      type: "ccc",
      author: "Neko Neko",
      datePublished: "12.04.2024.",
    },
    {
      id: "11",
      name: "TLorem Ipsum Colorit",
      shortDescription: "Kratak opis",
      type: "bbbbb",
      author: "Neko Neko",
      datePublished: "12.04.2024.",
    },
    {
      id: "12",
      name: "TLorem Ipsum Colorit",
      shortDescription: "Kratak opis",
      type: "bbbbb",
      author: "BNeko Neko",
      datePublished: "12.04.2024.",
    },
    {
      id: "13",
      name: "TLorem Ipsum Colorit",
      shortDescription: "Kratak opis",
      type: "aaaaa",
      author: "Neko Neko",
      datePublished: "12.04.2024.",
    },
  ];

  const options = [
    { value: "1", label: "Politika" },
    { value: "2", label: "Procedura" },
    { value: "3", label: "Radno uputstvo" },
  ];

  console.log("data koja treba", documents);
  return (
    <div className="px-6 ">
      {/* <Table
        headers={headers}
        data={data}
        buttons={buttons}
        headerNames={headerNames}
        role={userRole}
        searchText="listu zadataka"
        itemsPerPage="10"
        checkBox="show"
      /> */}
      <TableRowSelection
        data={data}
        headers={headers}
        headerNamesMapper={headerNamesMapper}
        button="download"
        itemsPerPage="8"
      />

      <div className="py-3">
        <button
          onClick={() =>
            openModal("ModalAdd", {
              content: [
                {
                  name: "name",
                  label: "Naziv ",
                  value: "",
                  type: "text",
                },
                {
                  name: "shortDescription",
                  label: "Kratak opis dokumenta",
                  value: "",
                  type: "text",
                },
                { name: "type", label: "Tip", type: "dropDown" },
                {
                  name: "author",
                  label: "Autor",
                  type: "text",
                },
                {
                  name: "date",
                  label: "Datum",
                  value: "",
                  type: "date",
                },
                {
                  name: "name",
                  label: "Otpremanje dokumenata",
                  type: "dragAndDrop",
                },
              ],
              type: "addDocument",
              options: options,
            })
          }
          className="bg-primary-800 dark:bg-primary-800 button-success hover:bg-gray-700 dark:hover:bg-gray-700"
        >
          Dodaj dokument
        </button>
      </div>
    </div>
  );
};

export default Documents;
