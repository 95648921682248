import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { lawsService } from "../services/lawsService";

// get list od laws
export const getListOfLaws = createAsyncThunk(
  "/lawList/get",
  async (thunkAPI) => {
    console.log("in async");
    try {
      return await lawsService.fetchLaws();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      console.log("msg", message);
      return thunkAPI?.rejectWithValue(message);
    }
  }
);
//get law by id
export const getLawById = createAsyncThunk(
  "/lawById/get",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return await lawsService.getLawById(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

const lawsSlice = createSlice({
  name: "laws",
  initialState: {
    lawsData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListOfLaws.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getListOfLaws.fulfilled, (state, action) => {
        state.loading = false;
        state.listOfLaws = action.payload;
      })
      .addCase(getListOfLaws.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLawById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLawById.fulfilled, (state, action) => {
        state.loading = false;
        state.lawDetails = action.payload;
      })
      .addCase(getLawById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default lawsSlice.reducer;
