import React, { useState } from "react";
import { Error } from "../../errors/FormValidationErrorField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isDateValid } from "../../utils/helpers/validations";
import Select from "react-select";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

//INPUT FIELD - React Final Form
export const InputField = ({
  input,
  label,
  disabled,
  icon,
  placeholder,
  meta,
  id,
}) => {
  return (
    <div className="w-full mb-5">
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white ">
        {label}
      </label>
      <input
        disabled={disabled}
        {...input}
        placeholder={placeholder}
        id={input.id}
        type={input.type}
        className={`${
          icon ? "input-primary-with-icon " : "input-primary"
        } border ${
          meta.error && meta.touched
            ? "border-red-600 dark:border-red-600"
            : "border-gray-300"
        }`}
      />
      {!disabled && <Error name={input.name} />}
    </div>
  );
};

//DROPDOWN
export const Dropdown = ({ input, options, text, onChange }) => {
  return (
    <div className="mt-2 mb-3">
      <select onChange={onChange} {...input} className=" input-primary">
        <option value="">{text}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {/* <Error name={input.name} /> */}
    </div>
  );
};

export const CustomMultiSelect = ({ input, meta, members, text, ...rest }) => {
  const customComponents = {
    Control: ({ innerProps, children }) => (
      <div className="flex p-3 mb-2 input-primary" {...innerProps}>
        {children}
      </div>
    ),
    Option: ({ innerProps, label }) => (
      <div className="custom-option" {...innerProps}>
        {label}
      </div>
    ),
  };

  const options = members.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  const handleChange = (selectedOptions) => {
    // Extract the value from the selected options
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    // Update the input value with the array of selected values
    input.onChange(selectedValues);
  };

  return (
    <>
      <Select
        {...input}
        {...rest}
        isMulti
        options={options}
        components={customComponents}
        placeholder={text}
        onChange={handleChange}
        value={options.filter((option) => input.value.includes(option.value))}
      />
      <Error name={input.name} />
    </>
  );
};

//InputField for Password
export const PasswordInputField = ({ input, text }) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative mt-2 mb-3">
      <input
        {...input}
        type={showPassword ? "text" : "password"}
        placeholder={text}
        className="w-full pr-10 input-primary"
      />
      <div
        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
        onClick={toggleShowPassword}
      >
        {showPassword ? (
          <EyeIcon className="w-5 h-5 text-gray-500" />
        ) : (
          <EyeSlashIcon className="w-5 h-5 text-gray-500" />
        )}
      </div>
      <Error name={input.name} />
    </div>
  );
};

//DATEPICKER - Incorporated with React Final Form
export const RenderDatePicker = ({
  name,
  disabled,
  input,
  input: { value, onChange },
}) => {
  const minDate = new Date(); // Set minimum date to the current day

  return (
    <div className="mb-3">
      <DatePicker
        dateFormat="P"
        selected={value && isDateValid(value) ? value : minDate}
        disabledKeyboardNavigation
        name={name}
        disabled={disabled}
        minDate={minDate}
        onChange={(date) => {
          // on change, you should use final-form field input prop to change the value
          if (isDateValid(date)) {
            // input.onChange(formatDate(new date(date), "dd-mm-yyyy"));
            input.onChange(date);
          } else {
            input.onChange(minDate);
          }
        }}
        className="mt-2 mb-2 input-primary "
      />
    </div>
  );
};

//TEXTAREA WITH LABEL ABOVE
export const TextAreaLabelAbove = ({ input, meta }) => {
  return (
    <div className="mt-3">
      <div className="">
        <textarea {...input} id={input.id} className="input-primary" rows="6" />
      </div>
      <Error name={input.name} />
    </div>
  );
};

//TEXTAREA WITH LABEL ABOVE
export const TextAreaComment = ({ input, meta, rows }) => {
  return (
    <div className="mt-3">
      <div className="">
        <textarea
          {...input}
          id={input.id}
          style={{
            borderColor: meta.error && meta.touched ? "red" : "",
          }}
          className="input-primary dark:bg-primary-800"
          rows={rows}
          placeholder="Napišite komentar..."
        />
      </div>
      <Error name={input.name} />
    </div>
  );
};

export const MultiSelectInput = ({
  input,
  meta,
  options,
  errorMessage,
  allChoices,
  onSelectChange,
  ...rest
}) => {
  const customComponents = {
    Control: ({ innerProps, children }) => (
      <div className="flex p-3 mb-2 input-primary" {...innerProps}>
        {children}
      </div>
    ),
    Option: ({ innerProps, label }) => (
      <div className="custom-option" {...innerProps}>
        {label}
      </div>
    ),
  };

  return (
    <>
      <Select
        {...input}
        {...rest}
        isMulti
        options={allChoices}
        components={customComponents}
        placeholder="Izaberite"
      />
      <Error name={input.name} />
    </>
  );
};
