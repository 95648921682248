import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dashboardService } from "../services/dashboardService";

//get data for dashboard projectCount,employeesCount,documentCount
export const fetchDashboard = createAsyncThunk(
  "/dashboard/get",
  async (thunkAPI) => {
    try {
      return await dashboardService.fetchDashboard();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardData: null,
    dashboardInfo: null,
    loadingDashboard: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboard.pending, (state) => {
        state.loadingDashboard = true;
        state.error = null;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.loadingDashboard = false;
        state.dashboardInfo = action.payload;
      })
      .addCase(fetchDashboard.rejected, (state, action) => {
        state.loadingDashboard = false;
        state.error = action.error.message;
      });
  },
});

export default dashboardSlice.reducer;
