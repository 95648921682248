import React, { useEffect } from "react";
import ListOfElements from "../../../common/ListOfElements";
import { useDispatch, useSelector } from "react-redux";
import { getListOfLaws } from "../../../../api/slices/lawsSlice";

const Laws = () => {
  const dispatch = useDispatch();

  const { listOfLaws } = useSelector((state) => state.laws);

  useEffect(() => {
    if (!listOfLaws) {
      dispatch(getListOfLaws());
    }
  }, [listOfLaws]);

  if (listOfLaws) {
    return <ListOfElements elements={listOfLaws} typeName={"Zakoni"} />;
  }
};

export default Laws;
