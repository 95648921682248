import axios from "axios";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  function (config) {
    config.headers["X-Http-Auth"] = JSON.parse(
      localStorage.getItem("userToken_complit")
    );
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
