import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { documentService } from "../services/documentService";

export const getDocuments = createAsyncThunk(
  "/documents/get",
  async (data, thunkAPI) => {
    try {
      return documentService.getList();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addDocument = createAsyncThunk(
  "/documents/add",
  async (data, thunkAPI) => {
    try {
      return data.file;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "/documents/add",
  async (data, thunkAPI) => {
    console.log("data");
    try {
      return data.file;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const bulkDeleteDocuments = createAsyncThunk(
  "/documents/add",
  async (data, thunkAPI) => {
    console.log("data");
    try {
      return data.file;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const filesSlice = createSlice({
  name: "files",
  initialState: {
    documents: null,
    successUpload: false,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.file = action.payload;
        state.successUpload = true;
      })
      .addCase(addDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.file = action.payload;
        state.successUpload = true;
      })
      .addCase(getDocuments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default filesSlice.reducer;
