//----------------------------------------STRING CHECH ERROR MESSAGES----------------------------------------

export const REQUIRED_FIELD_ERROR = "Ovo polje je obavezno.";
export const MUST_BE_NUMBER_ERROR = "Ovo polje može sadržati samo brojeve.";
export const PASSWORD_DONT_MATCH_ERROR = "Šifre se ne poklapaju.";
export const EMAIL_BAD_FORMAT_ERROR = "Loš format email adrese.";
export const SPECIAL_CHARACTERS_ERROR = "Nisu dozvoljeni specijalni karakteri.";
export const ONLY_CHARACHTERS_ALLOWED = "Dozvoljena su samo slova.";
export const SELECT_PROJECT_TYPE_ERROR = "Izaberite tip projekta.";
export const DATE_CONSISTENCY_ERROR =
  "Datum završetka projekta je pre datuma početka.";
export const MUST_BE_STRING_ERROR =
  "Dozvoljeni su samo brojevi i regularni karakteri. Specijalni karakteri nisu dozvoljeni.";

//---------------------------------------------API CODE MESSAGES---------------------------------------------

// const responseMessages = {
//   200: "Upload successful!",
//   400: "Bad request. Please check the file and try again.",
//   401: "Unauthorized. Please log in and try again.",
//   403: "Forbidden. You don't have permission to upload files.",
//   404: "Not found. The requested resource could not be found.",
//   500: "Internal server error. Please try again later.",
// };

// export default responseMessages;

const messages = {
  uploadSuccess: "Uspešno kreiranje!",
  uploadError: "Došlo je do greške prilikom kreiranja. Pokušajte ponovo.",
  invalidData: "Uneseni podaci su neispravni. Proverite i pokušajte ponovo.",
};

export default messages;
