import apiService from "./apiService";
import { createAsyncThunk } from "@reduxjs/toolkit";

//Fetching company members
export const fetchMembers = createAsyncThunk(
  "/members/get",
  async (thunkAPI) => {
    try {
      const response = await apiService.getData(`company/employees`);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);
//create new user
const createUser = async (data) => {
  const response = await apiService.postData(`userdetails`, data);
  return response;
};

//deactivate user
const deactivateUser = async (data) => {
  console.log("sadsdadasdas", data);
  const response = await apiService.deleteData(`company/deactivate-user`, data);
  return response.data;
};

export const memberService = {
  fetchMembers,
  createUser,
  deactivateUser,
};
