// projectSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postTask, fetchTasks, taskService } from "../services/taskService";
import PopupSuccess from "../../components/modals/PopupSuccess";
import ReactDOM from "react-dom";

// add task comment
export const addTaskComment = createAsyncThunk(
  "/task/add-comment",
  async (data, thunkAPI) => {
    try {
      return taskService.addTaskComment(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// get all task comments
export const getTaskComments = createAsyncThunk(
  "/task/get-comments",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return taskService.getTaskComments(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//get task by id
export const getTaskById = createAsyncThunk(
  "/task/get-taskById",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return taskService.getTaskById(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete task comment
export const deleteTaskComment = createAsyncThunk(
  "/task/delete-comment",
  async (data, thunkAPI) => {
    console.log("data", data);
    const { id } = data;
    try {
      return taskService.deleteTaskComment(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateTask = createAsyncThunk(
  "/task/update",
  async (data, thunkAPI) => {
    const { taskData } = data;
    try {
      return taskService.updateTask(taskData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// change task status
export const changeTaskStatus = createAsyncThunk(
  "/control/changestatus",
  async (data, thunkAPI) => {
    const { id } = data;
    //naci drugo resenje za ispisivanje response-a
    try {
      const response = await taskService.changeTaskStatus(data);
      if (response.code >= 200 && response.code <= 299) {
        ReactDOM.render(
          <PopupSuccess label="Status updated successfully" />,
          document.getElementById("popup-root")
        );
      }

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "/task/delete",
  async (data, thunkAPI) => {
    const { id } = data;
    try {
      return taskService.deleteTask(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// delete task files
export const deleteTaskFile = createAsyncThunk(
  "/task/delete-file",
  async (data, thunkAPI) => {
    console.log("data", data);

    try {
      return taskService.deleteFileTask({ fileName: data });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const uploadTaskFile = createAsyncThunk(
  "/taskfiles/add",
  async (data, thunkAPI) => {
    try {
      return data.file;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const taskSlice = createSlice({
  name: "task",
  initialState: {
    loading: false,
    file: null,
    loadingData: false,
    successfullUpload: false,
    error: null,
    taskDetails: null,
    taskData: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postTask.fulfilled, (state, action) => {
        state.loading = false;
        state.dataProject = action.payload;
      })
      .addCase(postTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTasks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addTaskComment.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.addedComment = null;
      })
      .addCase(addTaskComment.fulfilled, (state, action) => {
        state.loading = false;
        state.addCommentSuccess = true;
        state.addedComment = action.payload;
      })
      .addCase(addTaskComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getTaskComments.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.taskComments = null;
      })
      .addCase(getTaskComments.fulfilled, (state, action) => {
        state.loading = false;
        state.taskComments = action.payload;
      })
      .addCase(getTaskComments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getTaskById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.taskComments = null;
      })
      .addCase(getTaskById.fulfilled, (state, action) => {
        state.loading = false;
        state.taskDetails = action.payload;
      })
      .addCase(getTaskById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(changeTaskStatus.pending, (state) => {
        state.loadingData = true;
        state.error = null;
        state.taskData = null;
      })
      .addCase(changeTaskStatus.fulfilled, (state, action) => {
        state.loadingData = false;
        state.taskData = action.payload;
      })
      .addCase(changeTaskStatus.rejected, (state, action) => {
        state.loadingData = false;
        state.error = action.error.message;
      })
      .addCase(uploadTaskFile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadTaskFile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.file = action.payload;
      })
      .addCase(uploadTaskFile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default taskSlice.reducer;
