import apiService from "./apiService";

const getList = async () => {
  console.log("document getttt");

  const response = await apiService.getData("documents");
  const documents = response?.data?.documentedProtocols;
  return documents;
};
const add = () => {};
const deleteOne = () => {};
const bulkDelete = () => {};

export const documentService = {
  getList,
  add,
  deleteOne,
  bulkDelete,
};
