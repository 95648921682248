// Table.jsx
import React, { useEffect, useState } from "react";
import SearchBar from "../SearchBar";
import Pagination from "../Pagination";
import TableRecursiveRender from "./TableRecursiveRender";
// import useDataManipulation from "../../../custom_hooks/useDataManipulation";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useModal } from "../../../context/ModalContext";
import { useDispatch, useSelector } from "react-redux";
import { deactivateUser } from "../../../api/slices/memberSlice";
import BulkDeletePopup from "../../modals/BulkDeletePopup";
import { fetchMembers } from "../../../api/services/memberService";

const TableRowSelection = ({
  data,
  itemsPerPage,
  headers,
  headerNamesMapper,
  button,
  searchText,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { openModal, closeModal } = useModal();
  const { membersData } = useSelector((state) => state.member);
  // Filter data based on search query

  // const {
  //   filteredData: data,
  //   updateSortBy,
  //   searchQuery,
  //   updateSearchQuery,
  //   updateFilters,
  // } = useDataManipulation({ data: initialData });

  const dispatch = useDispatch();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // ---------------------------------- SEARCH LOGIC ---------------------------
  // Search by all fields
  const filteredData = data?.filter((item) =>
    Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  // Search by specific fields
  // const filteredData = data.filter((item) =>
  //   `${item.firstName} ${item.lastName}`
  //     .toLowerCase()
  //     .includes(searchQuery.toLowerCase())
  // );

  // ----------------------------------=------------------------------------------
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setCurrentPage(1); // Reset to the first page when the search query changes
    setSearchQuery(e.target.value);
  };

  const handleCheckboxChange = (id) => {
    const idExistsIndex = selectedItems.indexOf(id);

    //toggle checked
    if (idExistsIndex !== -1) {
      const freshItems = selectedItems.filter((x) => x !== id);
      setSelectedItems(freshItems);
    } else {
      setSelectedItems((prevState) => [...prevState, id]);
    }
  };

  // set checkbox to true/false to all the listed rows
  const handleAllCheckboxChange = (e) => {
    if (e.target.checked) {
      const itemIds = [];
      currentItems.forEach((item) => {
        if (!selectedItems.includes(item.id)) {
          itemIds.push(item.id);
        }
      });
      setSelectedItems(itemIds);
    } else {
      setSelectedItems([]);
    }
  };
  const handleDelete = (items) => {
    // dispatch(deleteItems(items));
    // setSelectedItems([]);

    console.log("brisanjee");
  };
  const handleRemoveSelected = () => {
    // list of ids to be removed
    const itemsToRemove = currentItems.filter((x) =>
      selectedItems.includes(x.id)
    );
    // deleting logic here
  };

  {
    /* <div className="filters">
          <div>
            <span>Status: </span>
            <select
              className="text-gray-500 dark:text-gray-400 dark:bg-primary-800 bg-gray-50"
              onChange={(e) => updateFilters("status", e.target.value)}
            >
              <option value="">Izaberi status</option>
              <option value={"Aktivan"}>Aktivan</option>
              <option value={"Nije aktivan"}>Nije aktivan</option>
            </select>
          </div>
        </div> */
  }
  return (
    <div className="relative overflow-x-auto sm:rounded-lg">
      <div className="flex items-center justify-between py-3 mr-1 lg:justify-between ">
        <div className="w-4 h-4">
          {selectedItems?.length > 0 && (
            <span
              className="cursor-pointer"
              onClick={() =>
                openModal("Modal1", {
                  text: `Da li ste sigurni da želite da deaktivirate korisnike?`,
                  close: closeModal,
                  confirm: () => {
                    dispatch(deactivateUser({ ids: selectedItems }));
                    dispatch(fetchMembers());
                  },
                })
              }
            >
              <BulkDeletePopup
                selectedItems={selectedItems}
                onDelete={handleDelete}
              />
            </span>
          )}
        </div>

        <SearchBar
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          type="search"
          searchBy={searchText}
          position="left"
        />
      </div>

      <table className="w-full text-sm text-left text-gray-500 rtl:text-right dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-primary-800 dark:text-gray-400">
          <tr>
            <td className="flex justify-center pt-4 pl-4">
              <input
                type="checkbox"
                onChange={(e) => handleAllCheckboxChange(e)}
              ></input>
            </td>
            {/* Map header names/titles */}
            {headers?.map((header, index) => (
              <th
                key={index}
                className="px-6 py-3 text-center cursor-pointer"
                // onClick={() => updateSortBy(header)}
              >
                {headerNamesMapper[header]}
              </th>
            ))}
            {button === "download" && (
              <td className="flex items-center px-3 py-3">
                <button className="icon-download"></button>
              </td>
            )}
            {button === "trash" && (
              <td className="flex items-center px-3 py-3">
                <p className="font-bold">Akcije</p>
              </td>
            )}
          </tr>
        </thead>

        <tbody>
          {currentItems?.map((item, rowIndex) => (
            <tr
              key={rowIndex}
              className="border-b cursor-pointer dark:hover:bg-primary-800 bg-gray-50 dark:bg-primary-750 dark:border-primary-800"
            >
              <td className="flex justify-center pt-4 pl-4">
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(item.id)}
                  checked={selectedItems.includes(item.id)}
                ></input>
              </td>
              {/* Go through whole table and map each hader with appropriate value in Object.  */}
              {headers?.map((header, colIndex) => {
                let data;

                switch (header) {
                  case "roleName":
                    data = item["role"]?.roleName;
                    break;
                  case "active":
                    if (item["deletedAt"] === null) {
                      data = "Aktivan nalog";
                      break;
                    } else {
                      data = "Deaktiviran nalog";
                      break;
                    }

                  default:
                    data = item[header];
                }

                return (
                  // This component go through object and recursevly prints elements in case that object is nested, pass "elem" so TableRecursiveRender
                  // know which key to target in nested array of objects (key-value pairs)
                  <TableRecursiveRender data={data} elem="title" />
                );
              })}
              {button === "download" && (
                <td className="flex items-center px-3 py-5">
                  <button className=" icon-download"></button>
                </td>
              )}
              {button === "trash" && (
                <td className="flex items-center px-6 py-6 whitespace-nowrap ">
                  <button
                    className=" icon-trash"
                    onClick={() =>
                      openModal("Modal1", {
                        text: `Da li ste sigurni da želite da deaktivirate korisnika?`,
                        close: closeModal,
                        confirm: () => {
                          dispatch(deactivateUser({ ids: [item.id] }));
                          dispatch(fetchMembers());
                        },
                      })
                    }
                  ></button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {filteredData?.length > itemsPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default TableRowSelection;
