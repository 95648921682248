import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

const withAuth = (WrappedComponent) => {
  const AuthComponent = (props) => {
    useEffect(() => {
      // Optionally, you can perform additional actions on component mount
      // For example, you might want to fetch user data or dispatch an action
    }, []);

    // const isAuthenticated = document.cookie.includes("your_auth_cookie_name=");
    const isAuthenticated = true;

    if (!isAuthenticated) {
      // Redirect to the login page if the user is not authenticated
      return <Navigate to="/auth/login" />;
    }

    // Render the original component if the user is authenticated
    return <WrappedComponent {...props} />;
  };

  return AuthComponent;
};

export default withAuth;
