// Komponenta Popup
import React, { useEffect, useState } from "react";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";
import CommentSideBar from "../dashboard_components/compliance/CommentSideBar";
import { addTaskComment, getTaskComments } from "../../api/slices/taskSlice";
import { useDispatch, useSelector } from "react-redux";

const PopupComment = ({ item }) => {
  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(true);
  };

  const addCommentHandler = ({ text }) => {
    console.log("will add comm", text);
  };

  const notifications = 5;
  return (
    <div className="relative">
      <div onClick={togglePopup} className="text-black rounded dark:text-white">
        <ChatBubbleLeftEllipsisIcon className="w-6 h-6 cursor-pointer" />
      </div>

      <div className="absolute flex items-center justify-center w-4 h-4 text-xs font-medium text-white bg-red-500 rounded-full -top-2 -right-2">
        {notifications}
      </div>
      {popupVisible && (
        <div className="absolute  bottom-0 max-h-[522px] right-0 z-20 w-64 p-2 transform translate-y-full overflow-y-auto border border-gray-300 rounded-md shadow md:w-96 lg:w-96 dark:border-gray-600 dark:bg-primary-800 bg-primary-55">
          <CommentSideBar
            taskId={item.id}
            onAddComment={addCommentHandler}
            onClose={() => setPopupVisible(false)}
          />
        </div>
      )}
    </div>
  );
};

export default PopupComment;
