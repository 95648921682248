import React, { useState } from "react";

const BulkDeletePopup = ({ selectedItems, onDelete }) => {
  const [visible, setVisible] = useState(true);

  const handleDelete = () => {
    onDelete(selectedItems);
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <div className="fixed z-50 flex items-center justify-center w-1/5 p-4 border-l-4 border-gray-400 rounded-lg shadow-md bg-primary-55 dark:bg-primary-800 bottom-4 right-4">
          <div className="flex items-center w-full">
            <div className="flex-grow font-medium text-black dark:text-white text-md">
              Izabrali ste {selectedItems.length} korisnika
            </div>
            <button
              onClick={handleDelete}
              className="px-4 py-2 ml-4 text-white bg-gray-800 rounded-md shadow-md hover:bg-slate-700"
            >
              Deaktiviraj korisnike
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default BulkDeletePopup;
