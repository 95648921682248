import React from "react";

const Item = ({ title, content }) => {
  return (
    <div className="flex-grow h-full p-2 lg:p-4 ">
      <div className="flex flex-col h-full p-3 text-center border border-gray-200 rounded-lg shadow bg-primary-55 md:items-center dark:bg-primary-800 dark:border-gray-700">
        <p className="text-md md:text-xl">{title}</p>
        <div className="m-auto text-md">
          <span>{content}</span>
        </div>
      </div>
    </div>
  );
};

export default Item;
