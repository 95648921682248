// // authService.js
// // const apiUrl = "http://complit.rs"; // Replace with your actual API endpoint
// const apiUrl = process.env.REACT_APP_API_URL;
// authService.js
import axios from "axios";
import { setUser, setError, logout, startLoading } from "../slices/authSlice";
import apiService from "./apiService";

// --------------------------- Async thunk for user login ---------------------------
export const login = (credentials) => async (dispatch) => {
  try {
    // Make API call for user login
    dispatch(startLoading());

    const response = await axios.post(
      "https://api.complit.rs/api/v1/auth-enc",
      credentials
    );

    console.log("response", response);
    const token = response.data.data.token;
    const pubKey = response.data.data.serverPubKey;
    localStorage.setItem("serverPubKey", pubKey);

    localStorage.setItem("userToken_complit", JSON.stringify(token));
    dispatch(loadUser(token));
  } catch (error) {
    // Dispatch action to set error in case of login failure
    console.log(error);

    dispatch(setError(error.response?.data?.errors?.error));
  }
};
// highSecuredPass123!
// --------------------------- Function to make API call for loading user data ---------------------------
export const loadUser = (token) => async (dispatch) => {
  try {
    // Include the token in the headers
    const response = await axios.get(
      "https://api.complit.rs/api/v1/userdetails",
      {
        headers: {
          "X-Http-Auth": `${token}`,
        },
      }
    );
    console.log(response);

    dispatch(setUser(response.data.data));
  } catch (error) {
    dispatch(setError(error.response.data.error));
  }
};

// --------------------------- Async thunk for user logout ---------------------------
export const logoutUser = () => (dispatch) => {
  // Add any cleanup logic if needed

  // Dispatch action to logout user
  dispatch(logout());
};

const updatePassword = async (data) => {
  const response = await apiService.postData(
    `userdetails/update/password`,
    data
  );
  return response;
};

const getLastLogin = async (data) => {
  const response = await apiService.getData(`userdetails/last-login`);
  return response;
};

const getActiveSessions = async (data) => {
  const response = await apiService.getData(`userdetails/active-sessions`);
  return response;
};

const resetSessions = async (data) => {
  const response = await apiService.postData(`userdetails/reset-sessions`);
  return response;
};

const updateUser = async (data) => {
  const response = await apiService.putData(`userdetails`, data);
  return response;
};

const deleteUser = async (id) => {
  const response = await apiService.deleteData(`userdetails/${id}`);
  return response;
};

const getUserDetails = async (id) => {
  const response = await apiService.getData(`userdetails/${id}`);
  return response;
};

const getAllUsers = async (id) => {
  const response = await apiService.getData(`company/${id}/employees`);
  return response;
};

const updateUserByAdmin = async (userId, data) => {
  const response = await apiService.putData(
    `userdetails/${userId}/update`,
    data
  );
  return response;
};

export const authService = {
  updatePassword,
  getLastLogin,
  getActiveSessions,
  resetSessions,
  updateUser,
  deleteUser,
  getUserDetails,
  getAllUsers,
  updateUserByAdmin,
};
