import React, { useEffect } from "react";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";

//Completed tasks in last seven days
// const data = [
//   { day: "Day 1", number: 10 },
//   { day: "Day 2", number: 5 },
//   { day: "Day 3", number: 7 },
//   { day: "Day 4", number: 9 },
//   { day: "Day 5", number: 10 },
//   { day: "Day 6", number: 23 },
//   { day: "Day 7", number: 15 },
// ];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`Broj rešenih zadataka: ${payload[0].value}`}</p>
        {/* <p className="mt-7 label">{`Broj novih zadataka: ${payload[1].value}`}</p> */}
        {/* Additional data or styling can be added here */}
      </div>
    );
  }

  return null;
};

const Activity = (completedTasksData) => {
  // const dataArray = [
  //   { day: "Day 1", number: completedTasksData.completedTasksData.day1 },
  //   { day: "Day 2", number: completedTasksData.completedTasksData.day2 },
  //   { day: "Day 3", number: completedTasksData.completedTasksData.day3 },
  //   { day: "Day 4", number: completedTasksData.completedTasksData.day4 },
  //   { day: "Day 5", number: completedTasksData.completedTasksData.day5 },
  //   { day: "Day 6", number: completedTasksData.completedTasksData.day6 },
  //   { day: "Day 7", number: completedTasksData.completedTasksData.day7 },
  // ];

  const dataArray = [];
  for (let i = 1; i <= 7; i++) {
    const day = `Day ${i}`;
    const number = completedTasksData?.completedTasksData[`day${i}`];
    dataArray.push({ day, number });
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={500} height={300} data={dataArray}>
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="day" padding={{ left: 30, right: 30 }} />
        <YAxis />
        {/* <Tooltip /> */}

        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey="number"
          stroke="#8884d8"
          activeDot={{ r: 6 }}
        />
        {/* <Line
          type="monotone"
          dataKey="number2"
          stroke="#976eba"
          activeDot={{ r: 6 }}
        /> */}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Activity;
