import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ModalView = ({ data, isOpen, index }) => {
  const { content, close } = data;
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleClick = (link) => {
    close(index);
    navigate(link);
  };

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full text-gray-400 bg-black bg-opacity-50">
      <div className="relative w-full max-w-2xl max-h-full p-4">
        <div className="relative w-full px-3 py-8 bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="popup-modal"
            onClick={() => close(index)}
          >
            <span className="w-6 ">
              <XMarkIcon />
            </span>
          </button>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg> */}

          {content.map((item, index) => (
            <div key={index} className="flex flex-col">
              <span className="text-lg font-semibold">{item.label}:</span>
              <span className="dark:text-gray-300">
                {item.link ? (
                  <button
                    className="text-blue-500"
                    onClick={() => handleClick(item.link)}
                  >
                    {item.value}
                  </button>
                ) : (
                  <span>{item.value}</span>
                )}
              </span>
            </div>
          ))}
          {/* {renderData(content)} */}
        </div>
      </div>
    </div>
  );
};

export default ModalView;
