import apiService from "./apiService";

//GET
const fetchStandards = async () => {
  const response = await apiService.getData(`standards`);
  //Returns response with additional field - temporary solution
  const standards = response.data?.standards?.map((x) => ({
    ...x,
    imgName: "ISO",
  }));
  return standards;
};
// get standars by id
const getStandardById = async (id) => {
  const response = await apiService.getData(`standards/${id}`);
  return response?.data?.standard;
};
// create standartd
const createStandard = async (data) => {
  const response = await apiService.postData(`standards/`, data);
  return response;
};

const updateStandard = async (id, data) => {
  const response = await apiService.putData(`standards/${id}`, data);
  return response;
};

const deleteStandard = async (id) => {
  const response = await apiService.deleteData(`standards/${id}`);
  return response;
};

export const standardService = {
  fetchStandards,
  getStandardById,
  createStandard,
  updateStandard,
  deleteStandard,
};
