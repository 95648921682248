import React from "react";
import { Link } from "react-router-dom";
import TypeWriterWithTitleAndArray from "../../utils/animated_text/TypeWriterTitleAndArray";
import TermsAndPrivacyPolicyFooter from "../../components/common/TermsAndPrivacyPolicyFooter";

const Home = () => {
  const data = [
    {
      title: "What is Lorem Ipsum?",
      text: [
        "Lorem Ipsum is simply  and typesetting industry.",
        "Lorem Ipsum has been the industry's ever since the 1500s.",
      ],
    },
    {
      title: "Why do we use it?",
      text: [
        "It is a long established fact that a reader",
        "The point of using Lorem Ipsum is that",
      ],
    },
  ];

  return (
    <div className="relative flex min-h-screen">
      <div className="absolute top-2 left-2 lg:text-white">
        <div className="flex text-2xl">Complit</div>
      </div>

      <div className="flex flex-col w-2/3 pl-2 text-white bg-gradient-to-r from-blue-400 to-white max-lg:hidden max-sm:hidden">
        {/* bg-gradient-to-r from-blue-500 to-cyan-500 */}
        <div className="flex items-center flex-grow justify-left">
          <TypeWriterWithTitleAndArray data={data} delay={100} infinite />
        </div>
      </div>

      <div className="flex flex-col items-center w-1/3 bg-white max-lg:w-full max-sm:w-full">
        <div className="flex flex-col items-center justify-center flex-1">
          <div className="mb-4 text-4xl font-bold text-primary-800">
            <span>Preduzmi akciju</span>
          </div>
          <div className="flex">
            <Link
              to="/auth/login/"
              className="px-8 py-5 text-lg font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600"
            >
              Login
            </Link>
            <Link className="px-8 py-5 ml-3 text-lg font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600">
              Schedule Demo
            </Link>
          </div>
        </div>

        <TermsAndPrivacyPolicyFooter />
      </div>
    </div>
  );
};

export default Home;
