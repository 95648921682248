import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { settingsService } from "../services/settingsService";

// get last login
export const getLastLogin = createAsyncThunk(
  "/lastLogin/get",
  async (thunkAPI) => {
    try {
      return await settingsService.fetchLastLogin();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);
// get all active sessions
export const getActiveSessions = createAsyncThunk(
  "/activeSessions/get",
  async (thunkAPI) => {
    try {
      return await settingsService.getActiveSessions();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);
//reset all active sessions, excluding the one you are using
export const resetSessions = createAsyncThunk(
  "/resetActiveSessions/get",
  async (thunkAPI) => {
    try {
      return await settingsService.resetActiveSessions();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);
//update password (set new password)
export const updatePass = createAsyncThunk(
  "/update-password/put",
  async (data, thunkAPI) => {
    try {
      return await settingsService.updatePass(data.data.values);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message;
      return thunkAPI?.rejectWithValue(message);
    }
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    loginData: null,
    activeSessionsData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLastLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLastLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.loginData = action.payload;
      })
      .addCase(getLastLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getActiveSessions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getActiveSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.activeSessionsData = action.payload;
      })
      .addCase(getActiveSessions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default settingsSlice.reducer;
