import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import ErrorCodePage from "../../../../pages/ErrorCodePage";
import InfoCard from "../../../common/InfoCard";
import ReadinessScore from "../ReadinessScore";
import Requirements from "../Requirements";
import { PageTitleContext } from "../../../../context/PageTitleContext";
import { mapDataToDTO } from "../../../../utils/helpers/helperFunctions";
import { getFrameworkById } from "../../../../api/slices/frameworkSlice";
import { useDispatch, useSelector } from "react-redux";

const FrameworkDetails = () => {
  const { setPageTitle } = useContext(PageTitleContext);

  const dispatch = useDispatch();

  const { frameworkDetails: framework } = useSelector(
    (state) => state.framework
  );
  //Get ID of item from URL and use it to fetch details
  const { id } = useParams();

  useEffect(() => {
    if (!framework || framework.id !== id) {
      dispatch(getFrameworkById({ id }));
    } else {
      setPageTitle(framework.title);
    }
  }, []);

  const { type } = useParams();

  return (
    <>
      <div className="w-full ">
        <div className="flex w-full max-lg:flex-col">
          <div className="px-6 pb-6 lg:w-1/2">
            {/*Show data array */}
            {framework && <InfoCard data={framework} type="project" />}
            {/* <FileUpload /> */}
          </div>
          <div className="pb-6 pr-6 lg:w-1/2 max-lg:pl-6 ">
            {/* Za project readiness se mogu prosledjivati podaci, admimnistratori ce videti overall napredak, regulare korisnici samo napredak za zadatke na koje su dodeljeni? */}
            <ReadinessScore id={id} type={type} />
          </div>
        </div>
        <div>
          {framework && <Requirements requirements={framework.requirements} />}
        </div>
      </div>
    </>
  );
};

export default FrameworkDetails;
