import React, { useContext, useState } from "react";
import MyProfile from "../../my_profile/MyProfile";
import SecuritySettings from "./SecuritySettings";
import { PageTitleContext } from "../../../context/PageTitleContext";

const SettingsPanel = () => {
  // const settings = [
  //   {
  //     id: "1",
  //     title: "Profile",
  //     area: "profile",
  //     control: [
  //       {
  //         id: "1",
  //         name: "Neko",
  //         surname: "Nekic",
  //         email: "neko.nekic@gmail.com",
  //       },
  //     ],
  //   },
  //   {
  //     id: "2",
  //     title: "Security",
  //     area: "security",
  //     control: [
  //       {
  //         id: "1",
  //         name: "Neko",
  //         surname: "Nekic",
  //         email: "neko.nekic@gmail.com",
  //       },
  //     ],
  //   },
  //   {
  //     id: "3",
  //     title: "Preference",
  //     area: "preference",
  //     control: [
  //       {
  //         id: "1",
  //         name: "Neko",
  //         surname: "Nekic",
  //         email: "neko.nekic@gmail.com",
  //       },
  //     ],
  //   },
  // ];
  const { setPageTitle } = useContext(PageTitleContext);
  setPageTitle("Podešavanja");
  const [selectedPage, setSelectedPage] = useState("my-profile");
  return (
    <div className="flex flex-col h-screen px-6 pb-8 md:flex-row">
      {/* Sidebar left*/}
      <div className="w-full p-4 bg-white rounded-md md:w-1/5 md:mr-3 dark:bg-primary-800">
        <div className="px-4 py-8">
          <ul className="flex flex-col ">
            <li>
              <button
                className="flex items-center w-full p-4 bg-white cursor-pointer dark:bg-primary-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-primary-750 hover:rounded-md sm:justify-start"
                onClick={() => setSelectedPage("my-profile")}
              >
                Moj profil
              </button>
            </li>
            <li>
              <button
                className="flex items-center w-full p-4 bg-white cursor-pointer dark:bg-primary-800 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-primary-750 hover:rounded-md sm:justify-start"
                onClick={() => setSelectedPage("security-settings")}
              >
                Security
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* Right panel */}
      <div className="flex-grow p-4 bg-white rounded-md dark:bg-primary-800">
        {selectedPage === "my-profile" && <MyProfile />}
        {selectedPage === "security-settings" && <SecuritySettings />}
      </div>
    </div>
  );
};

export default SettingsPanel;
