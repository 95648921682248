import React from "react";
import { Link } from "react-router-dom";

const TermsAndPrivacyPolicyFooter = () => {
  return (
    <div>
      <p className="mb-4 text-sm dark:text-white">
        <Link to="#">Uslovi korišćenja</Link> |{" "}
        <Link to="#">Politika privatnosti</Link>
      </p>
    </div>
  );
};

export default TermsAndPrivacyPolicyFooter;
