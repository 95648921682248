import React, { useCallback, useEffect, useState } from "react";
import ControlInfo from "./ControlInfo";
import { useModal } from "../../../../context/ModalContext";
import Table from "../../../common/tables/Table";
import { useDispatch, useSelector } from "react-redux";
import { fetchTasksForControl } from "../../../../api/slices/tasksForControlSlice";
import { fetchMembers } from "../../../../api/services/memberService";
import { formatDateTime } from "../../../../utils/helpers/formatDate";
import PopupFileUpload from "../../../modals/PopupFileUpload";

const ControlDetails = ({ control }) => {
  const { roleName: userRole } = useSelector((state) => state.auth.user?.role);
  const dispatch = useDispatch();
  const { id } = control;
  const { tasksData, loadingData } = useSelector(
    (state) => state.tasksForControl
  );
  const { membersData, loadingMembers } = useSelector((state) => state.member);
  const [updatedData, setUpdatedData] = useState([]);
  // fetch task for control
  useEffect(() => {
    dispatch(fetchTasksForControl({ id }));
  }, [dispatch, id]);

  //using formatDateTime func to format date
  useEffect(() => {
    if (tasksData && tasksData.length > 0) {
      const formattedData = tasksData.map((item) => ({
        ...item,
        startDate: formatDateTime(item.startDate),
        endDate: formatDateTime(item.endDate),
      }));
      setUpdatedData(formattedData);
    }
  }, [tasksData]);

  //fetch members for dropDown options
  useEffect(() => {
    {
      if (!membersData) {
        dispatch(fetchMembers());
      }
    }
  }, [membersData]);
  //options for dropDown menu
  const allChoices = [];
  if (!loadingMembers && membersData) {
    allChoices.push(
      ...membersData.data.employees.map((member) => ({
        value: member.id,
        label: member.firstName + " " + member.lastName,
      }))
    );
  }
  // options for update task status
  const options = [
    { value: "Not started", label: "Not started" },
    { value: "In progress", label: "In progress" },
    { value: "For review", label: "For review" },
    { value: "Finished", label: "Finished" },
  ];

  const taskHeaderName = [
    "ID",
    "Naziv",
    "Opis",
    "Datum od",
    "Datum do",
    "Zadužena osoba",
    "Status",
  ];
  const taskHeaders = [
    "id",
    "title",
    "description",
    "startDate",
    "endDate",
    "assignees",
    "status",
  ];

  const detailsButton = (row, openModal) => (
    <button className=" icon-show-more" onClick={() => openModal(row)}></button>
  );

  const taskButtons = [
    {
      //go to Table.js for onClick function
      label: "comment",
      type: "icon-comment",
    },
    // add file to task, tid is ID of task
    {
      label: "",
      onClick: (tid) =>
        openModal("ModalAdd", {
          content: [
            {
              name: "name",
              label: "Opis dokumenta",
              type: "text",
            },
            {
              name: "name",
              label: "Otpremanje dokumenta",
              type: "fileUpload",
            },
          ],
          type: "addFileToTask",
          tid: tid,
        }),
      type: "icon-document",
    },
  ];

  const { openModal, closeModal } = useModal();
  return (
    <div className="flex flex-wrap w-full p-6">
      <div className="w-full pb-3 ">
        {console.log(control)}
        <ControlInfo control={control} />
      </div>

      {/* display all tasks */}

      <hr className="w-full mt-2 mb-3 border-gray-400 opacity-30" />
      <div className="w-full pb-3 ">
        <div
          className={`${
            !control?.applicable ? "bg-blur cursor-not-allowed" : ""
          }`}
        >
          <div>
            <span className="py-4 pl-3 mt-4 text-xl font-bold">Zadaci</span>
            {/*=========== display all tasks=============== */}

            <Table
              data={updatedData}
              headerNames={taskHeaderName}
              buttons={taskButtons}
              role={userRole}
              headers={taskHeaders}
              itemsPerPage="3"
              detailsButton={detailsButton}
              disabled={!control.applicable}
            />
            {userRole === "admin" && (
              <button
                onClick={() =>
                  openModal("ModalAdd", {
                    content: [
                      {
                        name: "name",
                        label: "Naziv zadatka",
                        type: "text",
                      },
                      {
                        name: "description",
                        label: "Opis zadatka",
                        type: "text",
                      },
                      {
                        name: "startDate",
                        label: "Datum od",
                        type: "date",
                      },
                      {
                        name: "endDate",
                        label: "Datum do",
                        type: "date",
                      },
                      {
                        name: "status",
                        label: "Status",
                        type: "dropDown",
                      },
                      {
                        name: "assignees",
                        label: "Zadužena osoba",
                        type: "multipleSelect",
                      },

                      {
                        name: "name",
                        label: "Otpremanje dokumenata",
                        type: "dragAndDrop",
                      },
                    ],
                    type: "addTaskFile",
                    options: options,
                    members: allChoices,
                    id: id,
                  })
                }
                className="mt-3 bg-primary-750 dark:bg-primary-750 button-success hover:bg-gray-700 dark:hover:bg-gray-700"
                disabled={!control.applicable}
              >
                Dodaj zadatak
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlDetails;
