// Modal.js
import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const ModalDetails = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-scroll bg-gray-800 bg-opacity-75">
      <div className="p-14">
        <div className="w-full max-w-2xl overflow-hidden rounded-lg bg-primary-55 dark:bg-primary-800 ">
          <div className="flex justify-end p-4" onClick={onClose}>
            <XMarkIcon className="w-6 h-6 cursor-pointer" />
          </div>
          <div className="p-4">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalDetails;
